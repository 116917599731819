import { combineReducers } from "redux";
import { ActionCreator, AnyAction } from "redux";

import { createReducer } from "../../utils";
import { SUCCESS } from "../../utils/types/create-constants.types";

import {
  getRecipientsTypes,
  RecipientStoreConstants,
  editRecipientTypes,
  updateRecipientBankAccountTypes,
  updateRecipientPatialTypes,
} from "./recipient.constants";
import { IRecipientState, IRecipient } from "./recipient.types";

const { RECIPIENTS_LIST } = RecipientStoreConstants;

const updateRecipientInTheList: (
  state: IRecipient[],
  action: { payload: IRecipient },
) => IRecipient[] = (state, { payload }) => {
  return state.map(recipient =>
    recipient.recipientId === payload.recipientId ? payload : recipient,
  );
};

export const getRecipientsListReducer = createReducer<IRecipient[]>(
  {
    [getRecipientsTypes[SUCCESS]]: (_, { payload }) => payload.recipients,
    [editRecipientTypes[SUCCESS]]: updateRecipientInTheList,
    [updateRecipientBankAccountTypes[SUCCESS]]: updateRecipientInTheList,
    [updateRecipientPatialTypes[SUCCESS]]: updateRecipientInTheList,
  },
  [],
);

export default combineReducers<IRecipientState>({
  [RECIPIENTS_LIST]: getRecipientsListReducer,
});
