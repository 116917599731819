import { createConstants } from "../../utils";
// basic
const GET_RECIPIENTS = "GET_RECIPIENTS";
const ADD_RECIPIENT = "ADD_RECIPIENT";
const EDIT_RECIPIENT = "EDIT_RECIPIENT";
const UPDATE_RECIPIENT_PARTIAL = "UPDATE_RECIPIENT_PARTIAL";
const UPDATE_RECIPIENT_BANK_ACCOUNT = "UPDATE_RECIPIENT_BANK_ACCOUNT";

export const addRecipientTypes = createConstants(EDIT_RECIPIENT);
export const editRecipientTypes = createConstants(ADD_RECIPIENT);
export const getRecipientsTypes = createConstants(GET_RECIPIENTS);
export const updateRecipientPatialTypes = createConstants(
  UPDATE_RECIPIENT_PARTIAL,
);
export const updateRecipientBankAccountTypes = createConstants(
  UPDATE_RECIPIENT_BANK_ACCOUNT,
);

export enum RecipientStoreConstants {
  RECIPIENTS_LIST = "recipientsList",
}

// endpoints
export const GET_RECIPIENTS_ENDPOINT = "/recipient/find";
export const ADD_NEW_RECIPIENT_ENDPOINT = "/recipient/add";
export const EDIT_RECIPIENT_ENDPOINT = "/recipient/edit";
export const EDIT_RECIPIENT_PARTIAL_ENDPOINT = "/recipient/partialEdit";
